<div class="popup-confirm">
  <h2 mat-dialog-title>{{ data.title }}</h2>
  <br />
  <h4 mat-dialog-title>{{ data.message }}</h4>
  <div class="support-areas">
    <form [formGroup]="groupsForm">
      <label *ngFor="let area of data.supportAreas; let i = index">
        <input type="checkbox" [formControlName]="area.name" />
        <b> {{ area.name }} </b> {{ area.title }}
      </label>
    </form>
  </div>
  <br />
  <div mat-dialog-actions>
      <button mat-button (click)="onNoClick()">Cancelar</button>
      <button mat-button class="btn-crear" [disabled]="groupsForm.errors?.notChecked" (click)="onCreateClick()">{{data.btnLabel}}</button>
  </div>
</div>
