<div class="container-fluid m-3">
    <div class="filters">
        <mat-card class="mat-elevation-z3">
            <mat-card-title>
                Buscar Contingencia
                <span class="right">
                    <button mat-icon-button color="primary" aria-label="Limpiar" (click)="clean()">
                        <mat-label class="clean">Limpiar Campos</mat-label>
                        <mat-icon>backspace</mat-icon>
                    </button>
                </span>
            </mat-card-title>
            <br />
            <mat-card-content>
                <form [formGroup]="contingencyForm">
                    <div class="fields">
                        <div class="col-md-3">
                            <mat-form-field class="mr-3">
                                <mat-label>Vuelo</mat-label>
                                <input required onkeyup="javascript:this.value=this.value.toUpperCase();" matInput
                                    formControlName="vuelo" placeholder="Ej: LA500">
                            </mat-form-field>
                        </div>
                        <div class="col-md-3">
                            <mat-form-field class="mr-3">
                                <mat-label>Fecha Vuelo UTC</mat-label>
                                <input required matInput [matDatepicker]="picker" formControlName="fecha"
                                    placeholder="Selecciona una fecha">
                                <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                                <mat-datepicker #picker></mat-datepicker>
                            </mat-form-field>
                        </div>
                        <div class="col-md-3">
                            <mat-form-field class="mr-3">
                                <mat-label>Origen</mat-label>
                                <input onkeyup="javascript:this.value=this.value.toUpperCase();" matInput
                                    formControlName="origen" placeholder="Ej: SCL">
                            </mat-form-field>
                        </div>
                        <div class="action">
                            <div class="search">
                                <button mat-flat-button color="accent" [disabled]="loading" (click)="search()">
                                    <span *ngIf="loading" id="download-icon" class="material-icons">refresh</span>
                                    Buscar
                                </button>
                            </div>
                        </div>
                    </div>
                </form>
            </mat-card-content>
        </mat-card>
    </div>
    <br />
    <div style="width: 90%;" class="response" *ngIf="contingencyFlights">
        <h3 class="azul">Resultado:</h3>
        <div *ngFor="let leg of contingencyLegs">
            <div class=" mt-3">
                <div class="custom-card">
                    <div class="card-body">
                        <div class="row g-3">
                            <div class="col">
                                <div class="row contingency-box">
                                    <div class="col ">
                                        <h6 class="azul">{{ contingencyFlights.flight.dateUtc | date:'dd/MM/yyyy' }}
                                        </h6>
                                    </div>
                                </div>
                                <div class="row contingency-box">
                                    <div class="col">
                                        <h1 class="card-title bold">{{ contingencyFlights.flight.carrierCommercial }}{{
                                            contingencyFlights.flight.number }}</h1>
                                    </div>
                                </div>
                                <div class="row contingency-box">
                                    <div class="col sub-contingency-box">
                                        <div class="row">
                                            <h3 class="card-text bold">{{ leg.departure.airport }}</h3>
                                        </div>
                                        <div class="row">
                                            <h6 class="azul card-text">{{ leg.departure.blockOff.estimatedDateTime.utc |
                                                date:'HH:mm' }}</h6>
                                        </div>
                                    </div>
                                    <div class="col sub-contingency-box">
                                        <img class="airplane-ico bold" src="../../../assets/img/AIR005.svg"
                                            alt="Hello Image">
                                    </div>
                                    <div class="col sub-contingency-box">
                                        <div class="row">
                                            <h3 class="card-text bold">{{ leg.arrival.airport }}</h3>
                                        </div>
                                        <div class="row">
                                            <h6 class="azul card-text">{{ leg.arrival.blockOn.estimatedDateTime.utc |
                                                date:'HH:mm' }}</h6>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col  button-box centrado">
                                <div class="row justify-content-md-center button-box rojo">
                                    <h6 class="button-box-title">Opciones de sala de contingencia</h6>
                                </div>
                                <div class="row justify-content-md-center button-box">
                                    <div class="col sub-button-box"><button class="btn btn-text btn-lg" mat-flat-button
                                            color="primary" [disabled]="isOpenChatBtn(leg)"
                                            (click)="confirmDialogGoogleChat(leg,true)">Crear sala</button>
                                    </div>
                                </div>
                                <br>
                                <div class="row justify-content-md-center button-box">
                                    <div class="col sub-button-box"><button class="btn btn-text btn-lg" mat-flat-button
                                            color="primary" [disabled]="isNotOpenChatBtn(leg)"
                                            (click)="confirmDialogGoogleChat(leg,false)">Modificar sala</button>
                                    </div>
                                </div>
                                <br>
                                <div class="row justify-content-md-center button-box">
                                    <div class="col sub-button-box"><button class="btn btn-text btn-lg" mat-flat-button
                                            color="accent" [disabled]="isNotOpenChatBtn(leg)" (click)="closeSpaceChat(leg)">Cerrar sala</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<ngx-spinner bdColor="rgba(51, 51, 51, 0.8)" size="default" type="ball-spin-clockwise" style="position: absolute">
    <p style="color: white">{{ngxSpinner}} </p>
</ngx-spinner>