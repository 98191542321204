import { Injectable } from '@angular/core';
import { Constants } from '../common/constants';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class CcoService {

  constructor(private constants: Constants,
    private http: HttpClient) { }

  getFlights(request: any): Observable<any[]> {
    const URL = `${environment.apiUrl}${this.constants.CONTINGENCY_CHAT_GETFLIGHTS}?${this.serializeParams(request)}`;
    console.log(URL);
    const headers = this.addHeaders('GET');
    return this.http.get<any[]>(URL, { headers });

  }

  generateGoogleChat(request: any): Observable<any> {
    const URL = `${environment.apiUrl}${this.constants.CONTINGENCY_CHAT_CREATE_GOOGLE_CHAT}`;
    const headers = this.addHeaders('POST');
    return this.http.post<any>(URL, request, { headers });
  }

  searchGoogleChatInfo(request: any): Observable<any> {
    const URL = `${environment.apiUrl}${this.constants.CONTINGENCY_CHAT_SEARCH_GOOGLE_CHAT}`;
    const headers = this.addHeaders('POST');
    return this.http.post<any>(URL, request, { headers });
  }

  closeGoogleChat(request: any): Observable<any> {
    const URL = `${environment.apiUrl}${this.constants.CONTINGENCY_CHAT_CLOSE_GOOGLE_CHAT}`;
    const headers = this.addHeaders('POST');
    return this.http.post<any>(URL, request, { headers });
  }

  updateMembersGoogleChat(request: any): Observable<any> {
    const URL = `${environment.apiUrl}${this.constants.CONTINGENCY_CHAT_UPDATE_MEMBERS_GOOGLE_CHAT}`;
    const headers = this.addHeaders('POST');
    return this.http.post<any>(URL, request, { headers });
  }

  addHeaders(method): HttpHeaders {
    const headers = new HttpHeaders();
    headers.append('Access-Control-Allow-Headers', 'Content-Type');
    headers.append('Access-Control-Allow-Methods', method);
    headers.append('Access-Control-Allow-Origin', '*');
    headers.append('Content-Type', 'application/json');

    return headers;
  }
  private serializeParams(params: any): string {
    // Serializa los parámetros de la solicitud
    return Object.keys(params)
      .map(key => `${encodeURIComponent(key)}=${encodeURIComponent(params[key])}`)
      .join('&');
  }
}

