import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from "@angular/forms";
import { formatDate } from '@angular/common';
import { CcoService } from 'src/app/services/cco.service';
import { MatDialog } from '@angular/material/dialog';
import { ConfirmationDialogComponent } from '../confirmation-dialog/confirmation-dialog.component';
import { AlertDialogComponent } from '../alert-dialog/alert-dialog.component';
import { CookieService } from 'ngx-cookie-service';
import { NgxSpinnerService } from 'ngx-spinner';
import { LoginService } from 'src/app/services/login.service';
import { Router } from '@angular/router';
import { SecurityService } from 'src/app/services/security.service';

@Component({
  selector: 'app-cco',
  templateUrl: './cco.component.html',
  styleUrls: ['./cco.component.scss']
})
export class CcoComponent implements OnInit, OnDestroy {
  loading = false;
  private intervalId: any;
  contingencyForm: FormGroup;
  ngxSpinner = "Loading...";
  contingencyFlights: any;
  contingencyLegs: any;
  errorStations: Array<string> = [];
  airlineCode: String = "";
  flightDate: String = "";
  flightNumber: String = "";
  supportAreas: any[] = [
    { name: 'CPC', selected: false },
    { name: 'CCO', selected: false },
    { name: 'Dispatch', selected: false },
    { name: 'Crew Tracking', selected: false },
    { name: 'Cabin Crew', selected: false },
    { name: 'Flight crew', selected: false },
    { name: 'MOC', selected: false },
    { name: 'Maintenance Airport', selected: false },
    { name: 'Orig. Airport', selected: false },
    { name: 'Dest. Airport', selected: false },
    { name: 'APV-CATERING', selected: false },
    { name: 'HCC', selected: false },
    { name: 'Orig. Unico', selected: false },
  ];
  operationalChats: any[] = []

  constructor(
    private securityService: SecurityService,
    public router: Router,
    private loginService: LoginService,
    public dialog: MatDialog,
    private service: CcoService,
    private cookieService: CookieService,
    private spinnerService: NgxSpinnerService) { }


  ngOnInit(): void {
    this.initializeForm();
    this.intervalId = setInterval(() => {
      this.buildOperationalChats();
    }, 10000);
  }

  ngOnDestroy(): void {
    if (this.intervalId) {
      clearInterval(this.intervalId);
    }
  }

  confirmationMessage = '¿Estás seguro de realizar esta acción?';
  private initializeForm(): void {
    this.contingencyForm = new FormGroup({
      vuelo: new FormControl(''),
      origen: new FormControl(''),
      fecha: new FormControl(null, Validators.required),
    });
  }

  private formatDate(date: Date): string {
    return formatDate(date, 'yyyy-MM-dd', 'en-US');
  }

  clean(): void {
    this.contingencyForm.reset({
      vuelo: '',
      origen: '',
      fecha: null,
    });
    this.contingencyFlights = null;
    this.contingencyLegs = null;
  }

  search(): void {
    this.spinnerService.show();
    const formValues = this.contingencyForm.value;
    if (formValues.vuelo && formValues.vuelo.length >= 2) {
      const additionalParams = {
        flight_number: formValues.vuelo.replace(formValues.vuelo.substring(0, 2), ""),
        flight_date_utc: this.formatDate(formValues.fecha),
        airline_code: formValues.vuelo.substring(0, 2)
      };

      this.flightNumber = additionalParams.flight_number;
      this.airlineCode = additionalParams.airline_code;

      this.service.getFlights(additionalParams).subscribe(
        (data: any) => {
          if (data != null) {
            this.contingencyFlights = data;
            this.contingencyLegs = data.legs;
          } else {
            this.openAlertDialog("error", "Error recuperando información");
          }

          if (formValues.origen != "" && data != null) {
            this.contingencyLegs = data.legs.filter(leg => leg.departure.airport == formValues.origen.toUpperCase());
          }
          this.buildOperationalChats()
          this.spinnerService.hide();
        },
        (err) => {
          this.spinnerService.hide();
          this.openAlertDialog("error", "Tu sesión ha expirado. Vuelve a iniciar sesión", true);
        }
      );
    } else {
      this.spinnerService.hide();
    }
  }

  confirmDialogGoogleChat(leg: any, isCreate: boolean): void {
    const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
      width: '50%',
      data: { title: `¿Estas seguro de ${isCreate ? 'crear' : 'modificar'} la sala de contingencia?`, message: `Elige la opcion "${isCreate ? 'Crear sala' : 'Modificar sala'}" para ${isCreate ? 'generar' : 'actualizar'} la sala`, supportAreas: this.supportAreas, btnLabel: `${isCreate ? 'Crear sala' : 'Modificar sala'}` },
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        if (isCreate) {
          this.generateGoogleChat(leg, result);
        } else {
          this.updateMembersGoogleChat(leg, result);
        }
      } else {
        this.openAlertDialog("error", `Cancelado, No se ${isCreate ? 'creó' : 'modificó'} la sala.`);
      }
    });

  }

  generateGoogleChat(leg: any, groups: any): void {
    this.spinnerService.show();
    const additionalParams = {
      airlineCode: this.airlineCode,
      flightNumber: this.flightNumber,
      departureAirport: leg.departure.airport,
      arrivalAirport: leg.arrival.airport,
      flightDateLocal: this.contingencyFlights.flight.dateLocal,
      flightDateUtc: this.contingencyFlights.flight.dateUtc,
      accessToken: this.cookieService.get("googleApp"),
      supportAreas: groups,
      departureEstDtUtc: leg.departure.blockOff.estimatedDateTime.utc.replace(' ', 'T'),
      owner: this.contingencyFlights.legs[0].aircraft.owner,
      token: this.cookieService.get("id_token"),
      departureEstDtLt: leg.departure.blockOff.estimatedDateTime.local.replace(' ', 'T')
    };

    this.service.generateGoogleChat(additionalParams).subscribe(
      (data: any) => {
        this.spinnerService.hide();
        if (data != null) {
          this.openAlertDialog("success", "Datos enviados exitosamente para la creación del canal.");
          this.operationalChats.push({
            key: `${additionalParams.flightNumber}-${additionalParams.departureAirport}-${additionalParams.arrivalAirport}`,
            chatRoomName: undefined,
            chatRoomId: undefined,
          });
          this.buildOperationalChats();
        } else {
          this.openAlertDialog("error", "No se pudo crear la sala. Por favor, intentalo de nuevo.");
        }
      },
      (err) => {
        this.spinnerService.hide();
        console.log('Error recuperando información de Alteraciones Operacionales.', err);
        this.openAlertDialog("error", "No se pudo crear la sala. Por favor, intentalo de nuevo.", true);
      }
    );
  }

  updateMembersGoogleChat(leg: any, groups: any): void {
    const spaceId = this.createdSpace(leg)
    if (spaceId) {
      this.spinnerService.show();
      const additionalParams = {
        airlineCode: this.airlineCode,
        flightNumber: this.flightNumber,
        departureAirport: leg.departure.airport,
        arrivalAirport: leg.arrival.airport,
        flightDateLocal: this.contingencyFlights.flight.dateLocal,
        flightDateUtc: this.contingencyFlights.flight.dateUtc,
        accessToken: this.cookieService.get("googleApp"),
        spaceId: spaceId.chatRoomId,
        supportAreas: groups,
        departureEstDtUtc: leg.departure.blockOff.estimatedDateTime.utc.replace(' ', 'T'),
        owner: this.contingencyFlights.legs[0].aircraft.owner,
        token: this.cookieService.get("id_token"),
        departureEstDtLt: leg.departure.blockOff.estimatedDateTime.local.replace(' ', 'T')
      };

      this.service.updateMembersGoogleChat(additionalParams).subscribe(
        (data: any) => {
          this.spinnerService.hide();
          if (data != null) {
            this.buildOperationalChats()
            this.openAlertDialog("success", "Datos enviados exitosamente para la actualicación del canal.");
          } else {
            this.openAlertDialog("error", "No se pudo modificar la sala. Por favor, intentalo de nuevo.");
          }
        },
        (err) => {
          this.spinnerService.hide();
          console.log('Error recuperando información de Alteraciones Operacionales.', err);
          this.openAlertDialog("error", "No se pudo crear la sala. Por favor, intentalo de nuevo.", true);
        }
      );
    }
  }

  createdSpace(leg: any) {
    const key = `${this.contingencyFlights.flight.number}-${leg.departure.airport}-${leg.arrival.airport}`
    return (this.operationalChats && this.operationalChats.length > 0) ? this.operationalChats.find(element => element.key === key) : null
  }

  async buildOperationalChats(): Promise<void> {
    const operationalChatsList = [];
  
    if (this.contingencyLegs && this.contingencyLegs.length > 0) {
      for (let leg of this.contingencyLegs) {
        const additionalParams = {
          flightNumber: this.flightNumber,
          departureAirport: leg.departure.airport,
          arrivalAirport: leg.arrival.airport,
          flightDateUtc: this.contingencyFlights.flight.dateUtc,
        };
  
        try {
          const data: any = await this.service.searchGoogleChatInfo(additionalParams).toPromise();
          
          if (data != null) {
            operationalChatsList.push({
              key: `${additionalParams.flightNumber}-${additionalParams.departureAirport}-${additionalParams.arrivalAirport}`,
              chatRoomName: data.chatRoomName,
              chatRoomId: data.chatRoomId,
            });
          }
        } catch (error) {
          console.error('Error en searchGoogleChatInfo:', error);
        }
      }
    }

    this.operationalChats = operationalChatsList;
  }

  openAlertDialog(stateValue: String, messageValue: String, isClose?: boolean) {
    const dialogRef = this.dialog.open(AlertDialogComponent, {
      data: {
        state: stateValue,
        message: messageValue,
      },
    });

  }

  closeSpaceChat(leg: any): void {
    const spaceId = this.createdSpace(leg)
    if (spaceId) {
      this.spinnerService.show();
      const additionalParams = {
        airlineCode: this.airlineCode,
        flightNumber: this.flightNumber,
        departureAirport: leg.departure.airport,
        arrivalAirport: leg.arrival.airport,
        flightDateLocal: this.contingencyFlights.flight.dateLocal,
        flightDateUtc: this.contingencyFlights.flight.dateUtc,
        spaceId: spaceId.chatRoomId,
        accessToken: this.cookieService.get("googleApp"),
      };

      this.service.closeGoogleChat(additionalParams).subscribe(
        (data: any) => {
          this.spinnerService.hide();
          this.buildOperationalChats()
          this.openAlertDialog("success", "proceso finalizado");
        },
        (err) => {
          this.spinnerService.hide();
          console.log('Error recuperando información de Alteraciones Operacionales.', err);
          this.openAlertDialog("success", "proceso finalizado con observaciones");
        }
      );
    }

  }

  pad(value: number): string {
    return value < 10 ? `0${value}` : value.toString();
  }

  closeSesion() {
    this.router.navigateByUrl('login');
    this.loginService.setCurrentLoginState(false);
    this.securityService.logOut();
  }

  isNotOpenChatBtn(leg:any){
    const spaceId = this.createdSpace(leg)
    if(spaceId){
      return spaceId.chatRoomId === undefined
    }
    return true
  }

  isOpenChatBtn(leg:any){
    return this.createdSpace(leg) !== null
  }
}


