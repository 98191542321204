import { Injectable } from '@angular/core';

/* Commons */
const BFF_PATH = '/change-map-bff';
const LOGIN_API = '/login/validateUser';
const SAVE_ERROR = 'No se pudieron guardar los datos';
const PROCESS_ERROR = 'No se pudo recuperar la información';

const CONTINGENCY_CHAT_GETFLIGHTS = "/co-gestion-bff/v1/flight";
const CONTINGENCY_CHAT_ADMIN_ROOM = "/co-gestion-bff/v1/admin-room";
// ALTERATIONS OPERATIONALS
const ALTERATIONS_OPERATIONALS = '/operational-alteration/operational-alteration-filter';
const ALTERATIONS_OPERATIONALS_FLAG = '/operational-alteration/change-flag-manual';
const ALTERATIONS_PROCESS_ERROR = 'No se pudo recuperar la información';


// PENDINGs
const PENDING_EXECUTE = '/operational-alteration/operational-alteration-pending';
const PENDING_MASSIVE = '/change-configuration/pendingMassive';
const PENDING_MASSIVE_HISTORY = '/change-configuration/pendingMassiveHistory';
const PENDING_AUTO = '/batch-process/get-by-id-batch';
const PENDING_UPDATE = '/batch-process/update';
const PENDING_EXECUTE_MASSIVE = '/change-map/massive-upload-pending';

const LOAD_ERROR = 'No se pudo establecer conexion con el servidor de datos.';

// OTP
const CREATE_USERS = '/contingency-chat/inviteShare';

// POC GE
const GENERATE_FILE_POCGE = '/api/generatefile'

// CONFIGURATION CHANGE
const AIRCRAFT_REGISTRATION = '/aircraft-registration/get-all';
const CONFIGURATION_PROCESS_ERROR = 'No se pudo cargar la información de las matriculas';
const CONFIGURATION_EXECUTE = '/change-map/change-map-config-exec';

//MAINTAINERS
const M_FLEET_MM = '/fleet-mm/get-all';
const M_FLEET_HOST = '/fleet-host/get-all';
const M_FLEET_MM_SAVE = '/fleet-mm/save';
const M_FLEET_HOST_SAVE = '/fleet-host/save';
const M_FLEET_PROCESS_ERROR = 'No se pudo cargar la información de las flotas';

const M_AIRCRAFT_SAVE = '/aircraft-registration/save';
const M_AIRCRAFT_UPDATE = '/aircraft-registration/update';

const M_FLIGHT_INHIBITION = '/flight-inhibition/get-all';
const M_FLIGHT_INHIBITION_SAVE = '/flight-inhibition/save';
const M_FLIGHT_INHIBITION_UPDATE = '/flight-inhibition/update';
const M_FLIGHT_INHIBITION_DELETE = '/flight-inhibition/delete';

const M_FLIGHT_INHIBITION_RANGE = '/flight-range-inhibition/get-all';
const M_FLIGHT_INHIBITION_RANGE_SAVE = '/flight-range-inhibition/save';
const M_FLIGHT_INHIBITION_RANGE_UPDATE = '/flight-range-inhibition/update';
const M_FLIGHT_INHIBITION_RANGE_DELETE = '/flight-range-inhibition/delete';

const M_FLIGHT_EXCEPTION = '/exception-flight/get-all';
const M_FLIGHT_EXCEPTION_SAVE = '/exception-flight/save';
const M_FLIGHT_EXCEPTION_UPDATE = '/exception-flight/update';
const M_FLIGHT_EXCEPTION_DELETE = '/exception-flight/delete';

const M_FLIGHT_EXCEPTION_RANGE = '/exception-range-flight/get-all';
const M_FLIGHT_EXCEPTION_RANGE_SAVE = '/exception-range-flight/save';
const M_FLIGHT_EXCEPTION_RANGE_UPDATE = '/exception-range-flight/update';
const M_FLIGHT_EXCEPTION_RANGE_DELETE = '/exception-range-flight/delete';

const M_MAPAS = '/map/get-all';
const M_MAPAS_SAVE = '/map/save';
const M_MAPAS_UPDATE = '/map/save';

const M_MAP_GENERIC = '/configuration-rules/get-all';
const M_MAP_GENERIC_SAVE = '/configuration-rules/save';
const M_MAP_GENERIC_UPDATE = '/configuration-rules/update';
const M_MAP_GENERIC_DELETE = '/configuration-rules/delete';

const M_CABIN_TYPES = '/cabin-type/get-all';

// TRACKING COMMAND
const COMMAND_RESULT_FILTER = '/tracking-command/command-result-filter';
const COMMAND_REPROCESS = '/tracking-command/reprocess-command';

// SFTP
const SFTP_GET_PATH = '/sftp-aircom-sita/get-path';
const SFTP_GET_FILES_BY_PATH = '/sftp-aircom-sita/get-files-by-path';
const SFTP_GET_DOWNLOAD_FILES_BY_PATH = '/sftp-aircom-sita/download-files-by-path';
const SFTP_UPLOAD_FILES_BY_PATH = '/sftp-aircom-sita/upload-files-by-path';
const SFTP_GET_DIRECTORIES = '/sftp-aircom-sita/get-directories-sftp';
const SFTP_GET_UPDATED_DIRECTORIES = '/sftp-aircom-sita/refresh-directories-sftp';
const JTA_WORK_PACKAGE_UPLOAD = '/jta-work-package/upload'

//SFTP MESSAGES
const SFTP_ERROR_LOADING_DIRECTORIES = 'No se han podido cargar los directorios';

/* Roles */
const ROL_ADMIN = 'ADMIN';
const ROL_VIEWER = 'VIEWER';

/* HTTP status */
const HTTP_STATUS_OK = 'OK';
const HTTP_STATUS_ERROR = 'ERROR';

@Injectable()
export class Constants {
  // Commons
  BFF_PATH: string = BFF_PATH;
  LOGIN_API: string = LOGIN_API;
  PROCESS_ERROR = PROCESS_ERROR;
  CONTINGENCY_CHAT_GETFLIGHTS: String = CONTINGENCY_CHAT_GETFLIGHTS;
  CONTINGENCY_CHAT_CREATE_GOOGLE_CHAT: String = `${CONTINGENCY_CHAT_ADMIN_ROOM}?flow=create`;
  CONTINGENCY_CHAT_CLOSE_GOOGLE_CHAT: String = `${CONTINGENCY_CHAT_ADMIN_ROOM}?flow=close`;
  CONTINGENCY_CHAT_UPDATE_MEMBERS_GOOGLE_CHAT: String = `${CONTINGENCY_CHAT_ADMIN_ROOM}?flow=update`;
  CONTINGENCY_CHAT_SEARCH_GOOGLE_CHAT: String = `${CONTINGENCY_CHAT_ADMIN_ROOM}/search`;
  // ALTERATIONS OPERATIONALS
  ALTERATIONS_OPERATIONALS = ALTERATIONS_OPERATIONALS;
  ALTERATIONS_OPERATIONALS_FLAG = ALTERATIONS_OPERATIONALS_FLAG;
  ALTERATIONS_PROCESS_ERROR = ALTERATIONS_PROCESS_ERROR;
  TYPE_MESSAGE: string[] = TYPE_MESSAGE;
  TYPE_CHANGE: string[] = TYPE_CHANGE;
  STATUS: string[] = STATUS;

  // PENDINGs
  PENDING_EXECUTE = PENDING_EXECUTE;
  PENDING_MASSIVE = PENDING_MASSIVE;
  PENDING_MASSIVE_HISTORY = PENDING_MASSIVE_HISTORY;
  PENDING_AUTO = PENDING_AUTO;
  PENDING_UPDATE = PENDING_UPDATE;
  PENDING_EXECUTE_MASSIVE = PENDING_EXECUTE_MASSIVE;
  TYPE_OBS: string[] = TYPE_OBS;

  // OTP
  CREATE_USERS = CREATE_USERS;

  // POC GE
  GENERATE_FILE_POCGE: String = GENERATE_FILE_POCGE;

  // CONFIGURATION-CHANGE
  AIRCRAFT_REGISTRATION = AIRCRAFT_REGISTRATION;
  CONFIGURATION_PROCESS_ERROR = CONFIGURATION_PROCESS_ERROR;
  CONFIGURATION_EXECUTE = CONFIGURATION_EXECUTE;

  //TRACKING COMMAND
  TYPE = TYPE;
  SUBTYPE = SUBTYPE;
  SUBTYPE_MAP = SUBTYPE_MAP;
  COMMAND_RESULT_FILTER = COMMAND_RESULT_FILTER;
  COMMAND_REPROCESS = COMMAND_REPROCESS;

  // SFTP
  SFTP_GET_PATH = SFTP_GET_PATH;
  SFTP_GET_FILES_BY_PATH = SFTP_GET_FILES_BY_PATH;
  SFTP_GET_DOWNLOAD_FILES_BY_PATH = SFTP_GET_DOWNLOAD_FILES_BY_PATH;
  SFTP_UPLOAD_FILES_BY_PATH = SFTP_UPLOAD_FILES_BY_PATH;
  SFTP_GET_DIRECTORIES = SFTP_GET_DIRECTORIES;
  SFTP_GET_UPDATED_DIRECTORIES = SFTP_GET_UPDATED_DIRECTORIES;
  SFTP_ERROR_LOADING_DIRECTORIES = SFTP_ERROR_LOADING_DIRECTORIES;
  JTA_WORK_PACKAGE_UPLOAD = JTA_WORK_PACKAGE_UPLOAD;

  // MAINTAINERS
  M_FLEET_MM = M_FLEET_MM;
  M_FLEET_HOST = M_FLEET_HOST;
  M_FLEET_MM_SAVE = M_FLEET_MM_SAVE;
  M_FLEET_HOST_SAVE = M_FLEET_HOST_SAVE;
  M_FLEET_PROCESS_ERROR = M_FLEET_PROCESS_ERROR;

  M_AIRCRAFT_SAVE = M_AIRCRAFT_SAVE;
  M_AIRCRAFT_UPDATE = M_AIRCRAFT_UPDATE;

  M_FLIGHT_INHIBITION = M_FLIGHT_INHIBITION;
  M_FLIGHT_INHIBITION_SAVE = M_FLIGHT_INHIBITION_SAVE;
  M_FLIGHT_INHIBITION_UPDATE = M_FLIGHT_INHIBITION_UPDATE;
  M_FLIGHT_INHIBITION_DELETE = M_FLIGHT_INHIBITION_DELETE;

  M_FLIGHT_INHIBITION_RANGE = M_FLIGHT_INHIBITION_RANGE;
  M_FLIGHT_INHIBITION_RANGE_SAVE = M_FLIGHT_INHIBITION_RANGE_SAVE;
  M_FLIGHT_INHIBITION_RANGE_UPDATE = M_FLIGHT_INHIBITION_RANGE_UPDATE;
  M_FLIGHT_INHIBITION_RANGE_DELETE = M_FLIGHT_INHIBITION_RANGE_DELETE;

  M_FLIGHT_EXCEPTION = M_FLIGHT_EXCEPTION;
  M_FLIGHT_EXCEPTION_SAVE = M_FLIGHT_EXCEPTION_SAVE;
  M_FLIGHT_EXCEPTION_UPDATE = M_FLIGHT_EXCEPTION_UPDATE;
  M_FLIGHT_EXCEPTION_DELETE = M_FLIGHT_EXCEPTION_DELETE;

  M_FLIGHT_EXCEPTION_RANGE = M_FLIGHT_EXCEPTION_RANGE;
  M_FLIGHT_EXCEPTION_RANGE_SAVE = M_FLIGHT_EXCEPTION_RANGE_SAVE;
  M_FLIGHT_EXCEPTION_RANGE_UPDATE = M_FLIGHT_EXCEPTION_RANGE_UPDATE;
  M_FLIGHT_EXCEPTION_RANGE_DELETE = M_FLIGHT_EXCEPTION_RANGE_DELETE;

  M_MAPAS = M_MAPAS;
  M_MAPAS_SAVE = M_MAPAS_SAVE;
  M_MAPAS_UPDATE = M_MAPAS_UPDATE;

  M_MAP_GENERIC = M_MAP_GENERIC;
  M_MAP_GENERIC_SAVE = M_MAP_GENERIC_SAVE;
  M_MAP_GENERIC_UPDATE = M_MAP_GENERIC_UPDATE;
  M_MAP_GENERIC_DELETE = M_MAP_GENERIC_DELETE;

  M_CABIN_TYPES = M_CABIN_TYPES;

  // HTTP status
  HTTP_STATUS_OK = HTTP_STATUS_OK;
  HTTP_STATUS_ERROR = HTTP_STATUS_ERROR;

  LOAD_ERROR = LOAD_ERROR;
  SAVE_ERROR = SAVE_ERROR;
  // Others
  BASES_FILIAL: Map<string, string[]> = BASES_FILIAL;
  FILIAL_JJ: string = FILIAL_JJ;
  EXCLUDING_FILIAL: Map<string, string[]> = EXCLUDING_FILIAL;
  SNACKBAR_CLASS = SNACKBAR_CLASS;

  //SFTP AIRCOM SITA
  TYPE_SFTP_PATH: string[] = TYPE_SFTP_PATH;

}

// ALTERATIONS OPERATIONALS
const TYPE_MESSAGE = [
  'AA', 'AD', 'ETD', 'TIM', 'NEW', 'CNL', 'ALT', 'CON'
];

const TYPE_CHANGE = [
  'Manual', 'Automatico'
];

const STATUS = [
  'Successful',
  'Failed'
];

// PENDINGs
const TYPE_OBS = [
  'OK', 'EXCLUDE', 'ERROR'
];

//TRACKING COMMAND
const TYPE = ['ASM', 'MVT'];
const SUBTYPE = ['NEW', 'CON', 'CNL', 'EQT', 'RPL', 'TIM', 'RIN', 'FLT', 'RRT'];

const SUBTYPE_MAP = new Map<string, string[]>();
SUBTYPE_MAP.set('ASM', ['NEW', 'CON', 'CNL', 'EQT', 'RPL', 'TIM', 'RIN', 'FLT', 'RRT']);
SUBTYPE_MAP.set('MVT', ['EA', 'ED', 'AD', 'AA']);

// Others
const BASES_FILIAL = new Map<string, string[]>();
BASES_FILIAL.set('LA', ['SCL', 'IPC']);
BASES_FILIAL.set('LP', ['LIM']);
BASES_FILIAL.set('XL', ['UIO', 'GYE']);
BASES_FILIAL.set('4C', ['BOG']);
BASES_FILIAL.set('4M', ['EZE', 'AEP']);
BASES_FILIAL.set('JJ', ['GRU', 'CGH', 'POA', 'BSB', 'SDU', 'GIG']);
BASES_FILIAL.set('PZ', ['ASU']);
BASES_FILIAL.set('UC', ['SCL', 'MIA']);
BASES_FILIAL.set('L7', ['BOG', 'MIA']);
BASES_FILIAL.set('M3', ['GRU', 'VCP']);

const FILIAL_JJ = 'JJ';

const EXCLUDING_FILIAL = new Map<string, string[]>();
EXCLUDING_FILIAL.set('CC', ['FC']);
EXCLUDING_FILIAL.set('FC', ['CC']);

const MONITORING_STATUS: Map<string, boolean> = new Map<string, boolean>();
MONITORING_STATUS.set('NOK', false);
MONITORING_STATUS.set('WRN', false);
MONITORING_STATUS.set('OK', false);

enum SNACKBAR_CLASS {
  SUCCESS = 'ok-snackbar',
  WARNING = 'wrn-snackbar',
  ERROR = 'error-snackbar'
}

// SFTP AIRCOM SITA
const TYPE_SFTP_PATH = [
  '/sftp/IFNEO/tmp_test/', '/sftp/IFNEO/tmp_test2/', '/sftp/IFNEO/tmp_test3/'
];
